@font-face {
  font-family: "Recta Cond Bold";
  src: url("../fonts/recta/Recta-BoldCondensed-webfont.woff2") format("woff2"),
    url("../fonts/recta/Recta-BoldCondensed-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Recta Cond";
  src: url("../fonts/recta/Recta-Condensed-webfont.woff2") format("woff2"),
    url("../fonts/recta/Recta-Condensed-webfont.woff") format("woff");
  font-weight: 400;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Recta Medium";
  src: url("../fonts/recta/Recta-Medium-webfont.woff2") format("woff2"),
    url("../fonts/recta/Recta-Medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Recta Bold";
  src: url("../fonts/recta/Recta-Bold-webfont.woff2") format("woff2"),
    url("../fonts/recta/Recta-Bold-webfont.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Recta Bold Italic";
  src: url("../fonts/recta/Recta-BoldItalic-webfont.woff2") format("woff2"),
    url("../fonts/recta/Recta-BoldItalic-webfont.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Recta Light";
  src: url("../fonts/recta/Recta-Light-webfont.woff2") format("woff2"),
    url("../fonts/recta/Recta-Light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Recta Regular";
  src: url("../fonts/recta/Recta-Regular-webfont.woff2") format("woff2"),
    url("../fonts/recta/Recta-Regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Recta Italic";
  src: url("../fonts/recta/Recta-Italic-webfont.woff2") format("woff2"),
    url("../fonts/recta/Recta-Italic-webfont.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'URW Geometric Cond';
  src: url('../fonts/URWGeometricCondBold.woff2') format('woff2'),
      url('../fonts/URWGeometricCondBold.woff') format('woff'),
      url('../fonts/URW-Type-Foundry---URWGeometricCond-Bold.otf')
          format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric Cond';
  src: url('../fonts/URWGeometricCondBoldOblique.woff2') format('woff2'),
      url('../fonts/URWGeometricCondBoldOblique.woff') format('woff'),
      url('../fonts/URW-Type-Foundry---URWGeometricCond-BoldOblique.otf')
          format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric Cond';
  src: url('../fonts/URWGeometricCondRegular.woff2') format('woff2'),
      url('../fonts/URWGeometricCondRegular.woff') format('woff'),
      url('../fonts/URW-Type-Foundry---URWGeometricCond-Regular.otf')
          format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric Cond';
  src: url('../fonts/URWGeometricCondRegularOblique.woff2') format('woff2'),
      url('../fonts/URWGeometricCondRegularOblique.woff') format('woff'),
      url('../fonts/URW-Type-Foundry---URWGeometricCond-RegularOblique.otf')
          format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric Ext';
  src: url('../fonts/URWGeometricExtRegular.woff2') format('woff2'),
      url('../fonts/URWGeometricExtRegular.woff') format('woff'),
      url('../fonts/URW-Type-Foundry---URWGeometricExt-Regular.otf')
          format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric Ext';
  src: url('../fonts/URWGeometricExtExtraBold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric Ext';
  src: url('../fonts/URWGeometricExtExtraBoldOblique.woff2') format('woff2'),
      url('../fonts/URWGeometricExtExtraBoldOblique.woff') format('woff'),
      url('../fonts/URW-Type-Foundry---URWGeometricExt-ExtraBoldOblique.otf')
          format('opentype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric Ext';
  src: url('../fonts/URWGeometricExtRegularOblique.woff2') format('woff2'),
      url('../fonts/URWGeometricExtRegularOblique.woff') format('woff'),
      url('../fonts/URW-Type-Foundry---URWGeometricExt-RegularOblique.otf')
          format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric';
  src: url('../fonts/URWGeometric-SemiBold.woff2') format('woff2'),
      url('../fonts/URWGeometric-SemiBold.woff') format('woff'),
      url('../fonts/URW-Type-Foundry---URWGeometric-SemiBold.otf')
          format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric';
  src: url('../fonts/URWGeometric-Regular.woff2') format('woff2'),
      url('../fonts/URWGeometric-Regular.woff') format('woff'),
      url('../fonts/URW-Type-Foundry---URWGeometric-Regular.otf')
          format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric';
  src: url('../fonts/URWGeometric-RegularOblique.woff2') format('woff2'),
      url('../fonts/URWGeometric-RegularOblique.woff') format('woff'),
      url('../fonts/URW-Type-Foundry---URWGeometric-RegularOblique.otf')
          format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric';
  src: url('../fonts/URWGeometric-BoldOblique.woff2') format('woff2'),
      url('../fonts/URWGeometric-BoldOblique.woff') format('woff'),
      url('../fonts/URW-Type-Foundry---URWGeometric-BoldOblique.otf')
          format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric';
  src: url('../fonts/URWGeometric-Bold.woff2') format('woff2'),
      url('../fonts/URWGeometric-Bold.woff') format('woff'),
      url('../fonts/URW-Type-Foundry---URWGeometric-Bold.otf')
          format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric Light';
  src: url('../fonts/URWGeometric-Light.woff2') format('woff2'),
      url('../fonts/URWGeometric-Light.woff') format('woff'),
      url('../fonts/URW-Type-Foundry---URWGeometric-Light.otf')
          format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric Ext';
  src: url('../fonts/URWGeometricExtExtraBold.woff') format('woff'),
      url('../fonts/URW-Type-Foundry---URWGeometricExt-ExtraBold.otf')
          format('opentype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
