// for future themeing: 
// this sample div will change it's color and background properties based on the theme context
// this can be viewed by: changing the theme in /contexts/ThemeContext from 'light' to 'dark' and loading the /Login page

// reference article link: https://javascript.plainenglish.io/the-best-way-to-add-dark-mode-to-your-react-sass-project-ce3ae3bd8616
// codesandbox: https://codesandbox.io/s/admiring-shirley-kdewh?from-embed

// to apply this in our context:
// determine what theme to load based on cookie or URL parameter
// load the theme file and create relevant variables with the relevant theme colors for Insight and Resonate
// comb through this file and replace all colors with the @include themed() mixin and the correct attributes (bg, text, etc)
// comb through existing components and replace all colors with the @include themed() mixin and the correct attributes (bg, text, etc)

@import "_theme.scss";

.myClass {
  font-family: sans-serif;

  @include themed() {
    color: t('text');
    background: t('bg');
  }
}

html, body {
  height: 100%;
}

html {
  overflow: hidden;
}

body {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  font-family: "Recta Medium", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  font-variant: none !important;
  font-size: 16px !important;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

body.resonate-body { background-color: #f5f5f5; }
body.inspire-body { background-color: #fff; }


body .theme--resonate { background-color: #f5f5f5; }

#appContainer {
  min-height: calc(100vh - 140px);
}

#footer {
  height: 140px;
}

/* Basic Styles */

body h2 {
  font-weight: 900;
  color: #008080;
}

.fullWidthContainer a {
  color: #008080;
}

.fullWidthContainer a:hover {
  color: #006060;
}

/* Layout */

section.heroContainer,
.fullWidthContainer {
  width: 100%;
  left: 0;
  right: 0;
  background-position: 0% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.portfolioContainer {
  background-color: #f5f5f5;
}

.heroContainer {
  background-image: url(../images/Resonate/scoreboard-hero-bg-image-2.png);
}

.fullWidthContent {
  max-width: 1400px;
  position: relative;
  margin: 0 auto;
  padding: 15px 40px;
}

.fullWidthContent.header {
  padding: 20px 40px 25px 40px;
  z-index: 10;
  background-color: white;

  .headerIcon {
    color: #000;
  }
}

.fullWidthContent.hero {
  padding: 140px 40px 100px;
  color: white;
}

.heroNav {
  background-color: rgba(7, 17, 31, 0.24),
}

.whiteBg {
  background-color: white;
}

img.logoImg {
  width: 220px;
}

#footer img.logoImg {
  width: 200px;
}

#mainContent {
  padding: 2em 0;
}

.alignRight {
  display: flex;
  justify-content: flex-end;
}

/* Hero */

.fullWidthContent h1 {
  font-size: 4em;
  letter-spacing: -3px;
  font-weight: 800;
  margin: 0;
  padding: 0;
  color: #fff;
}

.fullWidthContent h4 {
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  letter-spacing: 1px;
  margin: 0;
}

ul.heroClientStats {
  display: flex;
  justify-content: end;
}

ul.heroClientStats li {
  margin-left: 35px;
  background-color: rgba(239, 247, 251, 0.14);
  box-shadow: 3px 3px 4px 0 rgb(0 0 0 / 11%);
  padding: 1.5em 1.25em 0.6em;
}

ul.heroClientStats li h4 {
  font-size: 4.43em;
  letter-spacing: -0.02em;
  line-height: 0.8em;
  height: 60px;
  text-align: center;
}

ul.heroClientStats li h4 i {
  font-size: 40px;
  position: relative;
  bottom: 20px;
  font-style: normal;
}

ul.heroClientStats li span.scoreLabel,
.cardRowHeader h4 span {
  text-transform: uppercase;
  display: block;
  font-weight: 700;
  font-size: 0.7em;
  text-align: center;
}

ul.heroClientStats li span.scoreLabel {
  font-size: 0.6em;
  letter-spacing: 1px;
  margin: 5px 0;
}

.cardRowHeader h4 strong {
  font-weight: normal;
}

.cardRowHeader h4 span {
  font-size: 0.5em;
  font-weight: normal;
  text-align: left;
}

/* Navigation and Menus */

nav ul,
ul.pointScore,
ul.screeningTickers,
div.selectMenuContainer ul,
div.contextMenuContainer ul,
ul.heroClientStats,
div#footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  font-weight: 900;
  text-transform: uppercase;
  font-size: 0.75em;
  letter-spacing: 2px;
  text-align: left;
}

nav.primaryNavigation {
  z-index: 5;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
  overflow: hidden;
}

nav.primaryNavigation.notifications {
  width: 500px;
  right: 100px;
}

nav.primaryNavigation.accountMenu {
  right: 40px;
}

nav.primaryNavigation h3 {
  font-size: 1.3em;
  font-weight: 900;
  margin: 0;
  text-align: left;
  padding: 0;
}
nav.primaryNavigation h4 {
  text-align: left;
  text-transform: none;
  margin: 0;
  color: #000;
  font-size: 1em;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}

nav.primaryNavigation ul li a,
div.selectMenuContainer ul li a,
div.contextMenuContainer ul li a {
  display: block;
  border-bottom: 1px solid #e4e4e4;
  text-decoration: none;
  padding: 1.25em 1.5em;
  color: #000 !important;
}

nav.inlineNavigation ul {
  padding: 7px 0 12px;
}

nav.inlineNavigation ul li {
  display: inline-block;
  margin-right: 20px;
}

nav.inlineNavigation ul li a {
  text-decoration: none;
  color: #7bcdcd;
  padding-bottom: 4px;
  border-bottom: 2px solid #7bcdcd;
  transition: all 0.3s;
}

nav.inlineNavigation ul li a:hover,
nav.inlineNavigation ul li a.active {
  color: white;
  border-bottom: 2px solid white;
}

div.selectMenuContainer,
div.contextMenuContainer {
  position: relative;
}

div.selectMenuContainer ul,
div.contextMenuContainer ul {
  width: inherit;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #d8dfdf;
  position: absolute;
  top: 38px;
  z-index: 30;
  overflow: hidden;
  max-height: 200px;
  overflow-y: visible;
}

div.selectMenuContainer ul {
  box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 7%);
  top: 43px;
}

.ant-drawer-body div.selectMenuContainer ul {
  top: 38px;
}

div.selectMenuContainer ul li a,
div.contextMenuContainer ul li a {
  padding: 1em 1em 0.7em;
  border: 0;
  font-weight: normal;
  font-size: 0.9em;
}

div.selectMenuContainer.error {
  button, .button {
    border: 1px solid #bd425a !important;
  }
}

div.contextMenuContainer ul {
  border: 1px solid #bac2ba;
  min-width: 100px;
  margin-left: -10px;
  border-radius: 4px;
}
div.contextMenuContainer ul li a {
  font-size: 0.8em;
  text-align: center;
}

div.selectMenuContainer.multiTitles ul li a {
  background-image: url('../images/select-unchecked.svg');
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
}

div.selectMenuContainer ul li.active a,
div.contextMenuContainer ul li.active a {
  background-color: #f1f1f1;
  background-image: url('../images/select check.svg');
  background-size: 10px;
  background-repeat: no-repeat;
}

div.selectMenuContainer ul li.active a,
div.contextMenuContainer ul li.active a {
  background-color: #f1f1f1;
  background-image: url('../images/select check.svg');
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
}

div.selectMenuContainer ul li a:hover,
div.contextMenuContainer ul li a:hover {
  background-color: #e0e7e7;
}

div.selectMenuContainer ul li.active.selected a:hover,
div.contextMenuContainer ul li.active.selected a:hover {
  background-image: url('../images/select-cancel.svg');
}

div#tickerSearchResults ul {
  top: 0;
  width: 100%;
}

div#tickerSearchResults ul li a {
  text-align: left;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
}

div#tickerSearchResults h4,
div#tickerSearchResults p {
  color: #000;
  margin: 0;
}

div#tickerSearchResults h4 {
  font-size: 1.2em;
}

div#tickerSearchResults p {
  font-size: 1.1em;
}

input.searchClients::placeholder {
  color: #ffffff;
}

input[type=text].searchClients, input[type=text].searchClients:focus {
  font-size: 0.9em;
  font-weight: 800;
  border-radius: 4px;
  border: 0;
  padding: 0.7em 1.6em 0.8em;
  min-width: auto;
  max-height: 45px;
}

input:focus {
  outline: none !important;
}

input.tickerSearch::placeholder {
  color: #000;
}

input[type=text].tickerSearch, input[type=text].tickerSearch:focus {
  text-overflow: ellipsis;
  background-position: 0 17px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-color: #fff;
  color: #000;
  font-size: 1.1em;
  font-weight: 700;
  border-radius: 4px;
  border: 0;
  padding: 0.7em 1.6em 0.8em;
  width: 100%;
  font-family: Recta Cond, sans-serif;
  overflow: hidden;
  white-space: nowrap;
}

/* Card Rows */

.cardRow {
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.07);
  margin-bottom: 1em;
  border-radius: 4px;
  /* overflow: hidden; */
}

.cardRow a {
  color: #000;
  text-decoration: underline;
  font-weight: 900;
}

.cardRowHeader {
  padding: 1.75em 2.5em;
  background-color: white;
}

.cardRowHeader div.row,
.cardRowContent div.row {
  display: flex;
  align-items: center;
  justify-content: start;
}

.cardRowHeader h3,
.cardRowHeader h4 {
  margin: 0;
  padding: 0;
  font-size: 2em;
  font-weight: 800;
  color: #000;
}

.cardRowHeader h3 {
  cursor: pointer;
}
.cardRowHeader h4 {
  font-weight: 400;
  font-size: 1.5em;
  letter-spacing: 0;
}

.cardRowHeader h3 a {
  text-decoration: none;
}

ul.pointScore {
  padding: 0.7em 0.2em 0.2em 1.2em;
  border-radius: 40px;
  border: 1px solid rgba(11, 25, 44, 0.11);
  width: fit-content;
  display: flex;
  background-color: #e7ecee;
}

ul.pointScore li,
div.pointScore,
ul.screeningTickers li,
div#footer ul li {
  margin-right: 13px;
  display: inline-block;
}

ul.pointScore li,
div.pointScore {
  display: flex;
}

ul.pointScore li i,
div.pointScore i,
ul.screeningTickers li a {
  width: 18px;
  height: 18px;
  border-radius: 40px;
  background-color: #229a9a;
  color: #fff;
  padding: 0;
  text-align: center;
  margin-right: 4px;
}

ul.screeningTickers {
  margin: 0px 0px 5px;
}

ul.screeningTickers.noRelevantData {
  padding: 1em 2.5em;
}

ul.screeningTickers li.label,
div#footer ul li {
  font-family: "Recta Regular", sans-serif;
  font-size: 0.8em;
}
ul.screeningTickers li a {
  transition: all 0.5s;
  background-color: #d8dfdf;
  border: 1px solid #d8dfdf;
  border-radius: 5px;
  width: fit-content;
  height: fit-content;
  color: #008080;
  text-decoration: none !important;
  font-family: "Recta Cond", sans-serif;
  letter-spacing: 1px;
  font-size: 0.9em;
  padding: 7px 7px 5px;
  margin-right: 0;
  margin-bottom: 10px;
}

ul.screeningTickers li a:hover,
ul.screeningTickers li a.activeIssueTicker {
  background-color: #008080;
  border: 1px solid #008080;
  color: #fff;
}

div#footer ul li {
  margin-right: 25px;
}

div#footer ul li,
div#footer ul li a {
  text-decoration: none;
  color: #6b6b6b;
}

div.pointScore i {
  margin-right: 6px;
}

ul.pointScore li i,
div.pointScore i {
  display: flex;
  align-content: center;
  justify-content: center;
}

ul.pointScore li.pointNegative i,
div.pointScore i.pointNegative {
  background-color: #bd425a;
}

ul.pointScore li i,
div.pointScore i {
  justify-content: center;
  align-content: center;
}

ul.pointScore li svg,
div.pointScore svg {
  position: relative;
  top: 4px;
}

ul.pointScore li span,
div.pointScore span {
  font-family: "Recta Cond", sans-serif;
  font-weight: 800;
  font-size: 1.1em;
  letter-spacing: -0.5px;
  position: relative;
  bottom: 4px;
  display: inline-block;
}

div.pointScore span {
  text-transform: uppercase;
  font-family: "Recta Medium", sans-serif;
  font-size: 0.8em;
  letter-spacing: 1.5px;
  position: relative;
  bottom: 1px;
}

div.pointScore span.issueCategoryClick {
  text-decoration: bold;
  cursor: pointer;
  transition: color 0.2s;
}

div.pointScore span.issueCategoryClick:hover {
  color: #008080;
}

ul.pointScore li.pointText {
  font-weight: 700;
  font-size: 0.8em;
  position: relative;
  bottom: 3px;
}

ul.pointScoreLarge {
  padding: 0.7em 0.5em 0.4em 1.4em;
}

ul.pointScoreLarge li i {
  width: 30px;
  height: 30px;
  bottom: 8px;
}

ul.pointScoreLarge li svg {
  bottom: 0;
  top: 2px;
}

ul.pointScoreLarge li span {
  font-size: 2em;
}

.heroContainer ul.pointScoreLarge {
  background: none;
  border: none;
  padding: 0;
}

.cardRowContent {
  padding: 1em 2.5em;
  background-color: #eff2f2;
  cursor: pointer;
}

.cardRowContent .holdingTitle h3 {
  cursor: pointer;
}

.cardRowContent h3,
.cardRowContent h5 {
  margin: 0;
  font-family: "Recta Cond", sans-serif;
}

.cardRowContent h5 {
  font-size: 1.15em;
}

.cardRowContentScreening {
  background-color: #e7ecee;
  border-bottom: 1px solid #d8dfdf;
  padding: 0;
}
.cardRowContentScreening .row:last-child {
  margin-bottom: 15px;
}

.cardRowContentTickerSearch {
  background-color: #fff;
}

.cardRowContent.holdingHeader,
.ant-table-thead tr {
  background-color: #e8efed;
  border-bottom: 1px solid #d8dfdf !important;
}

.cardRowContent.holdingHeader h4,
.ant-table-thead tr th {
  color: #000;
  font-family: "Recta Cond";
  font-size: 0.8em;
  font-weight: 400;
}

.cardRowContent.holdingHeaderLarge {
  background-color: rgb(245, 245, 245);
  border: 1px solid #d8dfdf;

  h4 {
    font-size: 0.8em;
    font-weight: 700;
  };
}

.cardRowContent h5.holdingAlignment {
  font-family: "Recta Cond", sans-serif;
  font-size: 0.95em;
}

.cardRowHeader,
.cardRowContent {
  border: 1px solid rgba(255, 255, 255, 0);
  border-bottom: 1px solid #e7ecee;
  transition: all 0.3s;
}

.cardRowContent {
  border-bottom: 1px solid #d8dfdf;
}

.cardRowHeader:hover {
  border: 1px solid #008080;
  background-color: #e7ecee;
  cursor: pointer;
}

.cardRowContent:hover {
  background-color: #e7ecee;
  border-left: 1px solid #d8dfdf;
  border-right: 1px solid #d8dfdf;
  border-bottom: 1px solid #d8dfdf;
}

.cardRowContent.cardRowContentTickerSearch:hover,
.cardRowContent.holdingHeader:hover {
  background-color: #e8efed;
  /* border-bottom: 1px solid #d8dfdf !important; */
  cursor: default;
}

.cardRowContent.cardRowContentTickerSearch,
.cardRowContent.cardRowContentTickerSearch:hover {
  background-color: #fff;
  border: none;
}

.cardRowContent.cardRowContent.openHolding,
.cardRowContent.cardRowContent.openHolding:hover,
.cardRowContent.cardRowContentScreening:hover {
  background-color: #e7ecee;
  border-left-color: #e7ecee;
  border-right-color: #e7ecee;
}

.issueRow {
  border-bottom: 1px solid #d8dfdf;
  padding: 1.4em 2.5em 0;
}

.issueRow:last-child {
  border-bottom: none;
}

.issuesContainer {
  background-color: #d8dfdf;
  padding: 30px;
  margin: 20px 0;
}

.issueBox {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #b8c6c6;
}

.issueBox:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.issuesContainer h3 {
  background-color: #008080;
  color: #fff;
  font-family: "Recta Bold", sans-serif;
  font-weight: 900;
  font-size: 14px;
  border-radius: 4px;
  padding: 0 8px 2px;
  display: inline-block;
  margin-bottom: 15px;
}

.issuesContainer h3 span {
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: 500;
  margin-left: 3px;
}

.issuesContainer p,
.issuesContainer h4,
.issuesContainer h4 a {
  color: #008080;
  font-size: 16px;
  font-family: "Recta Regular", sans-serif;
  line-height: 16px;
}

.issuesContainer h4,
.issuesContainer h4 a {
  text-transform: none;
  letter-spacing: normal;
  display: inline-block;
}

.modelContent {
  .cardRowContent.holdingHeader.row, .cardRow {
    margin: 0;
  };

  .cardRowHeader, .cardRowContent {
    padding: 0.8em 2.5em;
  };

  .cardRowHeader {
      h3, h4 {
      font-size: 1.17em;
    };
  };

  .cardRowContent {
    .holdingTitle {
      h3 {
        font-size: 1.1em;
      }
    }

    h5, input.dollarAmount {
      font-size: 1em;
    }

    span.dollarPrefix {
      font-size: 0.9em;
      margin-left: 17px;
    }
  }

  .cardRowContentTickerSearch, .cardRowContentTickerSearch:hover {
    padding: 0.3em 2.5em;
    border-bottom: 3px solid #e7ecee;

    input[type=text].tickerSearch, input[type=text].tickerSearch:focus {
      font-size: 0.9em;
      background-size: 15px;
      background-position: 2px 14px;
    };
  }

  .cardRowContentScreening {
    padding: 0;
  }

  .cardRow:last-child {
    .cardRowContentTickerSearch, .cardRowContentTickerSearch:hover {
      border-bottom: 0;
    };
  }
}

/* Modals and forms */

body.modalActive {
  overflow: hidden;
}

.modalOuterContainer {
  position: fixed;
  background-color: rgba(34, 154, 154, 0.9);
  height: 100%;
}

.modalContainer {
  padding: 30px;
  border-radius: 4;
  width: 550px;
  position: fixed;
  background-color: white;
  box-shadow: 2px 2px 6px 0 rgb(0 128 128 / 81%);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.fullWidth.modalOuterContainer {
  .modalContainer {
    width: 70%;
    overflow: auto;
    height: 80%;
  }
}

.modalCloseButton {
  top: 30px;
  right: 30px;
}

.loadingCheckout {
  color: grey

}

button,
.button {
  padding: 0.7em 1.6em 0.8em;
  transition: background-color 0.5s ease;
  font-size: 0.9em;
  font-weight: 800;
}

button.fullWidth,
.button.fullWidth {
  padding: 0.7em 0.7em 0.8em;
}

button:disabled, .button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.cardRowHeader button.fullWidth {
  max-width: 80%;
}

button.mini,
.button.mini {
  padding: 0.3em 0.5em;
}

button.button.transparent {
  background-color: transparent;
  color: #0b192c;
}
button.button.transparent:hover, button.button.transparent.hover { background-color: transparent; }

button.button.selectMenuButton {
  background-color: #d8dfdf;
  color: #0b192c;
}
button.button.selectMenuButton:hover, button.button.selectMenuButton.hover { background-color: #f7f7f7 }

button.button.heroPrimary {
  background-color: rgba(255, 255, 255, 0.23);
  color: #fff;
  max-height: 44px;
}
button.button.heroPrimary:hover, button.button.heroPrimary.hover { background-color: #008080; }

button.button.heroMini {
  background-color: rgba(6, 42, 73, 0.26);
  color: #fff;
}
button.button.heroMini:hover, button.button.heroMini.hover { background-color: rgba(6, 42, 73, 1); }

button.button.formPrimary {
  background-color: #008080;
  color: #fff;
}
button.button.formPrimary:hover, button.button.formPrimary.hover { background-color: #006969; }

button.button {
  background-color: #d8dfdf;
  color: #0b192c;
  font-family: "Recta Medium", sans-serif;
}
button.button:hover, button.button.hover { background-color: #e0e7e7; }

div.inputContainer {
  margin-bottom: 20px;
  position: relative;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea {
  font-size: 1em;
  font-weight: 500;
  border-radius: 4px;
  border: 0px;
  padding: 30px 15px 5px;
  min-width: 250px;
  font-family: "Recta Medium", sans-serif;
  position: relative;
  width: 100%;
  left: 0;
  background-color: #e7ecee;
  border: 1px solid #e7ecee;
  transition: all 0.3s;
}

input[type="text"]::selection,
input[type="email"]::selection,
input[type="password"]::selection,
input[type="tel"]::selection,
textarea::selection {
  background-color: #229a9a;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus,
textarea:focus {
  border: 1px solid #008080;
  outline: none;
}

input[type="text"].searchClients { 
  color: #ffffff;
  font-family: "Recta Regular", sans-serif;
}

textarea {
  min-height: 150px;
}

input[type="tel"]::-webkit-outer-spin-button,
input[type="tel"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

div.inputContainer.dollarContainer {
  display: flex;
  justify-content: left;
  align-items: center;
}

span.dollarPrefix {
  position: absolute;
  left: 0;
  margin-left: 16px;
  margin-top: 1px;
  z-index: 200;
  font-family: "Recta Cond", sans-serif;
  font-size: 1.1em;
}

.cardRowContent.cardRowContentTickerSearch span.dollarPrefix {
  margin-top: 0;
}

input.dollarAmount {
  border-radius: 4px;
  font-family: "Recta Cond", sans-serif;
  font-size: 1.15em;
  position: relative;
  z-index: 1;
  padding: 35px 15px 5px 24px;
  background-color: inherit;
  border-color: transparent;
}

body input.dollarAmount:disabled,
body input.dollarAmount[disabled] {
  color: rgba(0, 0, 0, 0.85) !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-opacity: 1;
  opacity: 1;
}

input.dollarAmount::placeholder {
  opacity: 60%;
}

input.dollarAmount:focus {
  border: 1px solid rgba(11, 25, 44, 0.31);
  background-color: #f0f0f0;
}

input.dollarAmount:focus::selection {
  color: #000;
  background-color: #d8dfdf;
}
input.dollarAmount::placeholder {
  color: rgba(0, 0, 0, 1);
}

div.inputContainer label,
div.radioContainer h3 {
  position: absolute;
  top: 10px;
  left: 16px;
  z-index: 5;
  font-size: 1em;
  font-weight: 900;
  border: 0px;
  font-family: "Recta Regular", sans-serif;
}

div.textareaContainer label {
  width: 97%;
  height: 35px;
  left: 1px;
  top: 1px;
  border-radius: 4px;
  padding: 10px 15px;
}

div.radioContainer, ul.multi-input-message {
  font-size: 1em;
  font-weight: 500;
  border-radius: 4px;
  border: 0px;
  padding: 10px 20px;
  min-width: 250px;
  font-family: "Recta Medium", sans-serif;
  position: relative;
  width: 100%;
  left: 0;
  background-color: #e7ecee;
  transition: all 0.3s;
}

div.radioContainer .col-xs-12 {
  display: flex;
  margin: 10px 0 0;
  padding: 10px;
  border-radius: 10px;
  background-color: #dce1e3;
  border: 1px solid #dce1e3;
  cursor: pointer;
}

div.radioContainer .col-xs-12.selected {
  border: 1px solid #008080;
}

div.radioContainer h3 {
  margin: 0;
  padding: 0;
  position: static;
}

div.radioContainer label {
  position: static;
  cursor: pointer;
}

div.radioInputContainer {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 100px;
  padding: 2px;
  width: 25px;
  height: 25px;
}

div.radioInputContainer input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  border: none;
  cursor: pointer;
}

div.radioInputContainer input[type="radio"] {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

div.selected div.radioInputContainer input[type="radio"],
div.radioInputContainer input[type="radio"]:checked {
  border: 3px solid #008080;
  background-color: #008080;
}

ul.multi-input-message {
  padding: 20px;

  li {
    margin-left: 0;
    list-style-position: inside;
    text-indent: 0;
    padding-left: 0;
    margin-bottom: 5px;
  }

  div.row {
    border-top: 1px solid #c8cdd0;
    padding: 5px 0;
  }

  div.row:last-child {
    border-bottom: 1px solid #c8cdd0;
  }
}

ul.multi-input-message.error { border: 1px solid #bd425a; }

div.inputContainer .error {
  border: 1px solid #bd425a;
}

p.input-message {
  font-weight: bold;
  margin-top: 5px;
}

p.input-message.error, .input-message.error {
  color: #bd425a;
  font-weight: bold;
  border: none;
}

div.inputContainer p.input-message.success {
  margin-top: 5px;
  color: #008080;
}

body div.selectMenuContainer .button,
body div.contextMenuContainer .button {
  transition: none !important;
}

div.selectMenuContainer input[type="text"] {
  padding: 0.65em 0.6em 0.75em;
  font-size: 0.9em;
  min-width: fit-content;
  font-weight: bold;
}

.playbookCriteriaRow {
  padding-bottom: 10px;
}
.playbookCriteriaRow:hover {
  background-color: #fff;
}

.positivePlaybookButton {
  background-color: #d8dfdf !important;
  box-shadow: #008080 0px 0px 0px 3px !important;
  color: #008080 !important;
}

.positivePlaybookButton.selected {
  background-color: #008080 !important;
  color: #fff !important;
}

/* Pagination Styles */
ul.pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

ul.pagination li {
  display: flex;
}

ul.pagination li a,
ul.pagination li.disabled {
  margin-right: 10px;
  border: none;
  transition: background-color 0.5s ease 0s;
  background-color: #d8dfdf;
  border-radius: 4px;
  text-align: center;
  font-size: 1em;
  box-shadow: none;
  color: rgb(11, 25, 44);
  font-weight: 900;
  position: relative;
  padding: 0.6em 1.2em 0.65em;
  /* min-width: 170px; */
  /* width: auto; */
  cursor: pointer;
  font-family: "Recta Medium", sans-serif;
}

ul.pagination li a:hover {
  background-color: #e0e7e7;
}

ul.pagination li.active a {
  background-color: #008080;
  color: #fff;
}

ul.pagination li.disabled {
  cursor: default;
  background-color: #e7e7e7;
  color: #9c9c9c;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* 3rd Party Overrides */

/* antd Table */

body .ant-table {
  box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 7%);
}

body .ant-table-thead > tr > th {
  padding: 0.8em 2.5em;
  border-bottom: 1px solid #d8dfdf;
}

body .ant-table-cell {
  cursor: pointer;
}

body .ant-table-tbody > tr > td {
  padding: 0.8em 1.7em;
  border-bottom: 1px solid #d8dfdf;
  font-family: "Recta Cond", sans-serif;
  font-size: 1.3em;
}

body .ant-table-tbody > tr:last-child > td {
  border-bottom: none;
}

body .ant-table a {
  color: #008080;
}

body .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: #e7ecee;
}

body .ant-table-thead > tr > th {
  background: none;
  color: #000;
  font-size: 0.8em;
  letter-spacing: 1px;
  text-transform: uppercase;
}

body .ant-table-column-sorter-up.active, body .ant-table-column-sorter-down.active { color: #008080; }

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}

/* antd Drawer */

body .ant-drawer {
  z-index: 300;
}

body .ant-drawer-body {
  background-color: #f5f5f5 !important;
  padding: 0;
  overflow-x: hidden;
}

body .ant-drawer-header {
  position: relative;
  padding: 40px 20px;
}

body .ant-drawer-title {
  position: absolute;
  font-weight: 900;
  color: #008080;
  font-size: 1.6em;
  line-height: 1.2em;
  padding-right: 10%;
}

body .ant-drawer-header-title button {
  position: absolute;
  right: 10px;
  z-index: 200;
}

body .ant-drawer-header-title button span svg {
  color: #000;
  stroke-width: 1em;
}

body .ant-drawer .ant-drawer-mask {
  background: rgba(34, 154, 154, 0.9);
}

body .ant-drawer.ant-drawer-open .ant-drawer-mask {
  opacity: 1;
}

/* Toastin' */

div.Toastify__toast-container {
  width: auto;
}

div.Toastify__toast {
  background: #dbe9e9;
  padding: 5px 50px 5px 10px;
  font-family: "Recta Medium", sans-serif !important;
  color: #008080;
  line-height: 20px;
  border-radius: 18px;
  font-weight: 800;
  border: 2px solid rgba(0, 128, 128, 0.3);
  box-shadow: 4px 4px 10px 0 rgb(0 128 128 / 11%);
  font-size: 20px;
  height: 70px;
  min-width: 450px;
}

div.Toastify__toast-body a:link,
div.Toastify__toast-body a:visited,
div.Toastify__toast-body a:hover {
  color: #008080;
}

body .Toastify__toast-icon {
  background-color: #008080;
  padding: 10px;
  margin-right: 18px;
  border-radius: 10px;
  width: 40px;
}

div.Toastify__toast--error {
  background: #f0e2e6;
  border-color: rgba(189, 66, 90, 0.3);
  color: #bd435a;
}

div.Toastify__toast--error a:link,
div.Toastify__toast--error a:visited,
div.Toastify__toast--error a:hover {
  color: #bd435a;
}

body div.Toastify__toast--error .Toastify__toast-icon {
  background-color: #bd435a;
}

div.Toastify__toast--warning {
  background: #f1e7e1;
  border-color: rgba(209, 120, 50, 0.3);
  color: #d17731;
}

div.Toastify__toast--warning a:link,
div.Toastify__toast--warning a:visited,
div.Toastify__toast--warning a:hover {
  color: #d17731;
}

body div.Toastify__toast--warning .Toastify__toast-icon {
  background-color: #d17731;
}

div.Toastify__toast--info {
  background: #f5f5f5;
  border-color: #cbd1d1;
  color: #585858;
  box-shadow: 4px 4px 10px 0 rgba(136, 136, 136, 0.17);
}

div.Toastify__toast--info a:link,
div.Toastify__toast--info a:visited,
div.Toastify__toast--warning a:hover {
  color: #6b6b6b;
}

body div.Toastify__toast--info .Toastify__toast-icon {
  background-color: #76a2b1;
}

.Toastify__toast-icon svg {
  fill: #fff !important;
  width: 20px;
}

.Toastify__close-button,
.Toastify__close-button--light {
  color: #15384b !important;
  opacity: 0.6 !important;
  position: absolute;
  top: 20px;
  right: 20px;
}

.Toastify__close-button:hover,
.Toastify__close-button--light:hover {
  opacity: 1 !important;
}

/* Mobile and Tablet Styles */

@media only screen and (max-width: 990px) {
  body.modalActive {
    overflow: unset;
  }

  #mainContent {
    padding: 0.3em 0;
  }

  .fullWidthContent {
    padding: 15px;
  }

  .fullWidthContent.header {
    padding: 19px 20px;
  }

  .fullWidthContent.hero {
    padding: 30px 20px;
    color: white,
  }

  .clientHeroContainer .fullWidthContent.hero {
    padding-top: 80px;
  }

  .fullWidthContent.header img.logoImg {
    width: 175px;
  }

  ul.heroClientStats {
    margin: 20px 0 0 0;
    justify-content: flex-start;
  }

  ul.heroClientStats li {
    padding: 1.3em 1em 0.6em;
    margin-left: 15px;
  }

  ul.heroClientStats li:first-child {
    margin-left: 0;
  }

  ul.heroClientStats li h4 {
    font-size: 3.5em;
    height: auto;
  }

  nav.inlineNavigation ul {
    padding-bottom: 0;

      li { 
      margin-bottom: 12px;
    }
  }

  #footer img.logoImg {
    width: 130px;
    margin: 0 auto;
    display: flex;
  }

  .cardRowHeader,
  .cardRowContent,
  .cardRowContent.holdingHeader {
    padding: 1em;
  }

  .cardRowContent.cardRowContentScreening {
    padding: 0;
  }

  .cardRowContent.holdingHeader {
    padding: 0.3em 1em;
  }

  .cardRowContent.holdingHeader h4 {
    font-size: 0.6em;
  }

  .cardRowContent .holdingTitle h3 {
    font-size: 1.1em;
  }

  .fullWidthContent h1 {
    font-size: 2.5em;
  }

  .cardRowHeader h3 {
    font-size: 1.7em;
  }

  .portfolioHeader h4 {
    font-size: 1.3em;
  }

  div#tickerSearchResults h4,
  div#tickerSearchResults p {
    font-size: 1em;
  }

  .modalOuterContainer {
    position: absolute;
    height: 150%;
  }

  .modalContainer, .modalOuterContainer.fullWidth .modalContainer {
    width: 100%;
    padding: 10px 20px 15px 10px;
    position: relative;
    left: auto;
    top: auto;
    transform: none;
  }



  .modalCloseButton {
    top: 15px;
    right: 25px;
  }

  button,
  .button {
    padding: 0.7em 0.4em 0.8em;
  }

  div.selectMenuContainer button,
  div.selectMenuContainer .button {
    padding: 0.7em 1em 0.8em;
  }

  nav.primaryNavigation.notifications,
  nav.primaryNavigation.accountMenu {
    right: 5vw;
  }

  nav.primaryNavigation.notifications {
    width: 80vw;
  }

  div.contextMenuContainer ul {
    margin-left: -20px;
  }

  div#tickerSearchResults ul {
    width: 105%;
  }

  div#tickerSearchResults ul li a {
    padding: 1em 1.3em 1em;
  }

  ul.pointScore {
    padding: 0.5em 0.2em 0 0.3em;
  }

  ul.pointScore li {
    margin-right: 5px;

    i {
      width: 16px;
      height: 16px;

      svg {
        top: 3px;
      }
    }
  }

  ul.pointScore li span {
    font-size: 0.9em;
    bottom: 3px;
  }

  ul.screeningTickers {
    padding: 0 0 0.3em;
    margin: 0;
  }

  ul.screeningTickers.noRelevantData {
    padding: 1em;
  }

  div.issueRow {
    padding: 1em;
  }

  div.cardRowContentScreening div.row:last-child {
    margin-bottom: 0;
  }

  div.pointScore span.issueCategoryClick {
    font-size: 0.7em;
    bottom: 0;
  }

  div.issuesContainer {
    padding: 15px;
    margin: 10px 0 0;
  }

  div.selectMenuContainer ul li a,
  div.contextMenuContainer ul li a {
    padding: 0.75em 1em 0.9em;
    font-size: 0.85em;
  }
}

@media only screen and (max-width: 767px) {
  .hideOnTablet {
    display: none;
  }

  // div.clientPagination div.col-xs-6:nth-child(3).hideOnMobile,
  // div.clientPagination div.col-xs-6:last-child.hideOnMobile {
  //   margin-top: 20px;
  // }

  .col-xs-6, .col-sm-6 {
    padding-top: 5px;
    padding-bottom: 5px;
  }


  .cardRowHeader.portfolioHeader div {
    margin-bottom: 5px;
  }

  .cardRowHeader.portfolioHeader div.row {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 575px) {
  // .col-sm-6:last-child,
  // .col-xs-6:last-child {
  //   margin-top: 10px;
  // }

  .alignRight {
    justify-content: flex-start;
  }

  .cardRowHeader .alignRight {
    justify-content: flex-end;
  }

  div.Toastify__toast {
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px) {
  body .hideOnMobile {
    display: none;
  }

  div.Toastify__toast {
    min-width: auto;
  }

  div.Toastify__toast-container--bottom-center {
    bottom: 0;
    margin: 5vw;
    width: 90vw;
  }
}

// Theme specfic styles: Insight

body.inspire-body, .inspire-body {
    background-color: #fff;
    color:rgb(11, 25, 44);
    font-family: "URW Geometric","Helvetica","Arial",sans-serif !important;
    position: relative;
    padding-bottom: 50px;

    // typography

    h1, h2 {
      color: rgb(11, 25, 44);
    }

    h2 {
      font-family: 'URW Geometric', sans-serif;
    }

    a {
      color: rgb(44, 138, 174);
      font-weight: bold;
      text-decoration: underline
    };

    .largeImpactScore, .extended-font-style {
      font-family: "URW Geometric Ext", sans-serif;
    }

    // layout 

    img.logoImg {
      width: 170px;
    }
    
    .fullWidthContent.header {
      padding: 15px 25px;

      .headerIcon {
        color: #0b192c;
      }
    }

    nav.primaryNavigation { 
      border: 1px solid #d9d9d9;
      z-index: 15;
      transition: none;
    }

    .portfolioContainer {
      background-color: #fff;
    }

    .heroContainer, .insightReportHeader {
      background: #fff;

      .heroNav {
        background: rgb(44, 138, 174);
      }

      h1 {
        font-family: "URW Geometric", sans-serif;
        font-size: 3em;
        letter-spacing: 0.1px;
        line-height: 1em;
        margin: 0 0 5px;
        padding: 0;
      }

      h4 {
        font-size: 1.9em;
        font-family: "URW Geometric", sans-serif;
        line-height: 1em;
        text-transform: capitalize;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        letter-spacing: 0;
      }

      nav.inlineNavigation ul li a {
        font-family: 'URW Geometric', sans-serif;
        font-size: 1.2em;
        color: #fff;
        border-bottom: 0;
        text-decoration: none;
      }

      nav.inlineNavigation ul li > a.active { 
        border-bottom: 1px solid white;
      }

      input[type=text] {
        padding: 0.7em 1.6em 0.8em;
      }

      ul.heroClientStats li {
        box-shadow: none;
        border: 1px solid #dbdbdb;
        background-color: #fff;
        border-radius: 4px;
      }

      ul.heroClientStats li h4 {
        font-size: 4.03em;
      }
      ul.heroClientStats li span.scoreLabel {
        color: #000;
      }
    }

    .clientPagination {
      border-bottom: 1px solid #dbdbdb;
      
      ul.pagination li.active a {
        color: #fff;
        text-decoration: none;
        background-color: rgb(44, 138, 174);
      }
    }

    .footerContainer {
      background-color: #f7f7f7;
      border-top: 1px solid #dbdbdb;
      padding: 15px 20px;
      position: fixed;
      bottom: 0;
      z-index: 250;

      ul {
        list-style: none;
        margin: 0; 
        padding: 0;
      }

      ul li {
        margin-right: 10px;
        display: inline-flex;
        vertical-align: top;
      }

      ul li:last-child { margin-right: 0;}

      a, p {
        color:#6b6b6b;
        font-size: 0.75em;
        text-decoration: none;
        font-weight: normal;
        margin: 0;
      }
    }

    // forms and buttons

    .button {
      background-color: #e7e7e7;
      color: #000;
      font-size: 0.9em;
      font-family: "URW Geometric Ext","Helvetica","Arial",sans-serif;
      cursor: pointer;
    };

    .button:hover {
      background-color: #cfcfcf;
    }

    .button.formPrimary {
      background-color: rgb(44, 138, 174);
      color: #fff;
    }

    .button.formPrimary:hover {
      background-color: rgb(36, 110, 139); 
    }

    .button.fullWidth {
      padding: 0.9em 0.7em 0.8em;
    };

    .button.transparent {
      background-color: transparent;
    }

    hr {
      border: none;
      border-top: 1px solid #efefef;
      margin: 20px 0;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    textarea {
      border-radius: 3px;
      padding: 33px 15px 10px;
      background-color: #e3eaee;
      font-family: "URW Geometric","Helvetica","Arial",sans-serif;
    };

    input[type="text"]:focus,
    input[type="email"]:focus,
    input[type="password"]:focus,
    input[type="tel"]:focus,
    textarea:focus {
      border-color: rgb(44, 138, 174);
    };

    input[type="text"]::selection,
    input[type="email"]::selection,
    input[type="password"]::selection,
    input[type="tel"]::selection,
    textarea::selection {
      background-color: rgb(44, 138, 174);
      color: #fff;
    };

    input[type="text"].plain, input[type="text"].plain:focus {
      background-color: #fff;
      border: 1px solid #d9d9d9;
    }

    div.inputContainer input.error {
      border: 1px solid #bd425a;
    };

    div.inputContainer label, div.radioContainer h3 {
      font-family: "URW Geometric Ext","Helvetica","Arial",sans-serif;
      top: 10px;
      font-size: 1em;
    };

    div.radioContainer label {
      font-weight: normal;
      letter-spacing: 0;
      padding-top: 3px;
    };

    div.radioContainer .col-xs-12.selected {
      border-color: rgb(44, 138, 174);
    };

    div.selected div.radioInputContainer input[type=radio], div.radioInputContainer input[type=radio]:checked {
      border-color: rgb(44, 138, 174); 
      background-color: rgb(44, 138, 174);
    };

    .modalOuterContainer {
      background-color: rgba(44, 138, 174, 0.9);
    }
    
    .modalContainer {
      box-shadow: 2px 2px 6px 0 rgb(0 120 167 / 81%);
    }

    #insightHeader {
      position: relative;

      a {
        color: #000;
        text-decoration: none;
        font-weight: 100;
      };
    }

    nav.primaryNavigation ul li a,
    div.selectMenuContainer ul li a,
    div.contextMenuContainer ul li a {
      text-decoration: none;
    }

    div.selectMenuContainer, div.noLabelInputContainer {
      input[type="text"] {
        padding: 0.7em 1em;
      }
    }

    div.noLabelInputContainer {
      input[type="text"] {
        
      }
    }



    // pages

    // Insight Home and Subpages

    input#searchmaster {
      padding: 18px 20px;
      background-color: #fff;
      border: 1px solid #cacfd1;
      padding-right: 59px;
    }

    input#searchmaster:hover, input#searchmaster:focus {
      box-shadow: 1px 1px 6px -2px rgb(11 25 44 / 51%);
    }

    input#searchmaster::placeholder {
      color: rgb(11, 25, 44);
    }

    div#tickerSearchResults {
      margin-left: 12px;

      ul {
        max-height: 500px;

        a {
          text-decoration: none;
          padding: 3px 8px;
          border-bottom: 1px solid #c7c9cc;
        };
  
        li:last-child a {
          border-bottom: none;
        };

        p {
          padding-top: 5px;
          font-family: 'URW Geometric Cond', sans-serif;
          font-size: 1.2em;
          font-weight: 100;
          margin: 0;
        };
  
        p strong {
          font-weight: bold;
        };
      };

      span.extended-font-style {
        padding-left: 5px;
      }

      span.negativeScore {
        color: #bd425a !important;
        padding-left: 0;
      };
    }

    .homeMiniTickers {
      padding: 0;
      margin: 0 0 50px;

      h3, p {
        font-family: 'URW Geometric Cond', sans-serif;
        font-size: 0.9em;
        line-height: 1em;
        margin: 0; 
      }

      h3 { margin-bottom: 10px }
      
      .miniTicker {
        border-bottom: 1px solid #c7c9cc;
        width: 100%;
      }
    }

    .largeImpactScore {
    }

    .instrumentTicker { padding: 0 0 80px }

    .instrumentTitle {
      padding-top: 15px;

      h1, h3 {
        font-family: 'URW Geometric', sans-serif
      };

      h1 {
        font-size: 3em;
        letter-spacing: 0.1px;
        margin: 0;
        padding: 0;
        line-height: 1em;
      };

      h3 {
        font-size: 1.9em;
        line-height: 1em;
      }
    };

    .instrumentMeta {
      display: flex;
      justify-content: right;

      .detail {
        background-color: #eff7fb;
        border: 1px solid #2c8aaf;
        border-radius: 4px;
        color: #2c8aaf;
        padding: 1em 0.85em 0.65em;
        font-family: 'URW Geometric Cond', sans-serif;
        max-width: 150px;
        min-width: 150px;
        max-height: 105px;
        font-size: 16px;

        strong {
          font-size: 3.4em;
          font-weight: 100;
          line-height: 0.8em;
          display: block;
          font-family: 'URW Geometric', sans-serif;
        };
      }

      .detail.negative {
        background-color: rgba(189, 66, 90, 0.05);
        color: #bd425a;
        border-color: rgba(189, 66, 90, 0.5);
      }

      .detail:nth-child(2) { margin-left: 10px }
    }

    // Insight Dashboard

    .cardRow {
      border: 1px solid #dbdbdb;
    }

    .cardRowHeader {
      background-color: #f5f5f5;
    }

    .cardRowHeader:hover {
      border-color: rgb(44, 138, 174);
    }

    .cardRowContent {
      // padding: 1em 2.5em;
      background-color: #fff;
      border-left: none;
      border-right: none;
      cursor: pointer;
    }

    .cardRowContent:hover {
      // background-color: #eff1f4;
      border-left: none;
      border-right: none;
    }

    .cardRowContent.cardRowContent.openHolding {
      background-color: #eff1f3; 
    }
    
    .cardRowContent.cardRowContent.openHolding,
    .cardRowContent.cardRowContent.openHolding:hover,
    .cardRowContent.cardRowContentScreening:hover {
      background-color: #f9f9f9;
      border-left: none;
      border-right: none;
    }

    .cardRowContent.cardRowContentTickerSearch:hover {
      background-color: #fff;
      border: 0;
    }

    .cardRowContent.cardRowContentScreening {
      border-bottom: 1px solid #e7ecee; 
    }

    input[type=text].tickerSearch, input[type=text].tickerSearch:focus {
      padding: 0.7em 1.6em 0.8em;
      background-color: #fff;
      font-family: 'URW Geometric Cond', sans-serif;
    }

    input[type="text"].dollarAmount {
      background-color: #fff;
      padding: 35px 15px 5px 24px;
      font-size: 1.1em;
      font-family: 'URW Geometric Cond', sans-serif;
    }

    span.dollarPrefix {
      font-size: 1em;
      margin-top: -1px;
    }

    .positivePlaybookButton {
      background-color: rgb(226, 234, 237) !important;
      box-shadow: rgb(44, 138, 174) 0px 0px 0px 3px !important;
      color: rgb(44, 138, 174) !important;
    }
    
    .positivePlaybookButton.selected {
      background-color: rgb(44, 138, 174) !important;
      color: #fff !important;
    }
    
    // Instrument Issues and Funds Table

    .cardRowContentScreening {
      border-bottom: 0;
      background-color: #f9f9f9;
    }

    .instrumentMeta {
      margin-bottom: 30px;
    }

    .issueRow {
      border-bottom: 1px solid #dbdbdb;
      padding-top: 1.2em;
    }

    .issueRow:hover {
      background-color: #f5f5f5;
      transition: all 0.3s;
    }

    .issuesContainer {
      background-color: #f0f0f0; 

      h3 span, p, h4, h4 a { font-family: 'URW Geometric', sans-serif; }
      h3 span { font-weight: 900}
    }

    div.pointScore {
      span.issueCategoryClick:hover { color: rgb(44, 138, 175)}

      i {
        background-color: rgb(44, 138, 175);
        min-width: 18px;
      }

      i.pointNegative {
        background-color: #bd425a;
      }
    }

    ul.screeningTickers li a {
      color: rgb(44, 138, 175);
    }

    .issuesContainer h3, ul.screeningTickers li a:hover, ul.screeningTickers li a.activeIssueTicker {
      background-color: rgb(44, 138, 175);
      border-color: rgb(44,138,175);
      color: #fff;
    }

    .issuesContainer p, .issuesContainer h4 {
      color: #000;
    }

    .issuesContainer .issueBox {
      border-bottom: 1px solid #dbdbdb;
      
      h4 { display: block }
      h4.issueUrlCondensed {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    div.insightReportHeader {
      padding-top: 40px;
      padding-bottom: 0;

      h2 {
        color: #fff;
        font-size: 1.25em;
        padding: 10px 20px;
        margin-bottom: 20px;
        color: rgb(44, 138, 174);
        background-color: #e3eaee;
        border-radius: 4px;
      }

      div.graphBarContainer, div.insightReportColumn {
        h4 {
          font-weight: 700;
          font-size: 1.4em;
          font-family: 'URW Geometric', sans-serif;
          margin-bottom: 15px;
        }

        h5 {
          font-weight: 600;
          font-size: 1.2em;
          display: inline-flex;
          margin-right: 10px;
        }

        div.graphBar {
          display: inline-flex;
          border: 2px solid #000;
          width: 86%;
          height: 30px;
          border-radius: 4px;

          span {
            display: inline-block;
            height: 26px;
          }
        }
        
        .reportStatistics {
          padding: 10px 0 0 60px;
          margin-bottom: 15px;
  
          .row {
            margin-bottom: 5px;
          }

          h5 {
            display: inline;
          }
  
          h5 span {
            padding-top: 5px;
            margin-bottom: 0;
            font-size: 1em;
            font-weight: 600;
            display: inline;
            padding-left: 5px;
            background-color: #fff;
            color: #000;
          }
  
        }
      }

      div.positive {
        h5 {
          color: rgb(44, 138, 174);
        }

        div.graphBar {
          border-color: rgb(44, 138, 174);
          background-color: #e3eaee;
        }

        span {
          background-color: rgb(44, 138, 174);
        }
      }

      div.negative {
        h2 {
          color: #b34d4d;
          background-color: #f0e2e6;
        }

        h5 {
          color: #b34d4d;
        }

        div.graphBar {
          background-color: #f0e2e6;
          // border-color: rgba(189, 66, 90, 0.3);
          border-color: #bd435a;

        }
        span {
          background-color: #b34d4d;
        }
      }
    }

    /* antd tings */

    .ant-drawer .ant-drawer-mask {
      background-color: rgba(44, 138, 174, 0.9);
    }

    .ant-drawer-title {
      color: rgb(11, 25, 44);
    }

    .cardRowContent.holdingHeader, .ant-table-thead tr {
      background-color: #f9f9f9;
    }

    .ant-table-tbody > tr > td.ant-table-cell-row-hover, div.selectMenuContainer ul li a:hover,
    div.contextMenuContainer ul li a:hover { 
      background-color: #f1f1f1;
    }

     .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
      color: rgb(44, 138, 175);
     }
     
     .ant-pagination-item-active, .ant-pagination-item:hover, .ant-pagination-item-active:hover, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: rgb(44, 138, 175);
     }

     .ant-table-tbody > tr > td span.extended-font-style {
      font-family: 'URW Geometric Ext', sans-serif;
     }

     .ant-slider-rail, .ant-slider-dot {
      background-color: #e7e7e7;
     }

     .ant-slider-dot {
      border-color: #e7e7e7;
     }

     .ant-slider-dot-active {
      border-color: rgb(44 138 175);
     }

     .ant-slider-track, .ant-slider:hover .ant-slider-track, .ant-slider-dot-active {
      background-color: rgb(44, 138, 175);
     }

     .ant-slider:hover .ant-slider-handle, .ant-slider-handle {
      margin-top: -6px;
      width: 16px;
      height: 16px;
      border-color: rgba(44, 138, 175);
      border-width: 4px;
     }

     .ant-slider-handle:focus { box-shadow: none;}
     .ant-slider-handle.ant-tooltip-open::after {
      display: none;
     }

     .ant-tooltip {
      font-size: 15px;
      font-family: 'URW Geometric', sans-serif;
     }

     .ant-slider-mark-text, .ant-slider-mark-text-active {
      color: #000;
      text-align: left;
      transform: none !important;
      margin-left: -4px;
     }

     div.ant-slider-mark:last-child span.ant-slider-mark-text:last-child {
      text-align: right;
      left: unset !important;
      right: 0%;
      transform: none !important;
     }

     .ant-input-number {
      display: flex;
      flex-direction: column;
      width: 50px;
      margin-top: 5px;
      height: 35px;

      input.ant-input-number-input, input.ant-input-number:hover {
        font-family: 'URW Geometric', sans-serif;
        font-size: 16px;
        padding-top: 3px;
        text-align: center;
        border-color: #d9d9d9;
      }

      input::selection {
        background-color: rgb(44, 138, 174);
        color: #fff;
      }
     }

     .ant-input-number-focused, .ant-input-number-hover, .ant-input-number:hover {
      border: 1px solid #d9d9d9;
      box-shadow: none;
     }

     .ant-slider {
      width: 74%;
      margin-left: 15px;
      margin-right: 15px;
     }



     /* toastin */

     div.Toastify__toast {
      background: rgb(226, 234, 237);
      padding: 5px 50px 5px 10px;
      font-family: "URW Geometric", sans-serif !important;
      color: rgb(44, 138, 175);
      line-height: 20px;
      border-radius: 18px;
      font-weight: 700;
      border: 2px solid rgba(44, 138, 175, 0.3);
      box-shadow: 4px 4px 10px 0 rgb(44 138 175 / 11%);
      font-size: 20px;
      height: 70px;
      min-width: 450px;
    }
    
    div.Toastify__toast-body a:link,
    div.Toastify__toast-body a:visited,
    div.Toastify__toast-body a:hover {
      color: rgb(44, 138, 175);
    }
    
    .Toastify__toast-icon {
      background-color: rgb(44, 138, 175);
      padding: 10px;
      margin-right: 18px;
      border-radius: 10px;
      width: 40px;
    }

     @media only screen and (max-width: 990px) {
     }
    

    @media only screen and (max-width: 900px) {
      .fullWidthContent.header { 
        padding: 0 15px;
      }

      img.logoImg {
        width: 140px;
        padding: 7px 0;
      }

      .heroContainer { 
        h1 {
          font-size: 2.2em;
        }
        h4 {
          font-size: 1.5em;
        }
      }

      .instrumentTitle {
        padding-top: 5px;

        h3 {
          font-size: 1.2em;
        }
      };
    }

    @media only screen and (max-width: 767px) {
      #searchmaster, #tickerSearchResults {
        position: absolute;
        top: 90px;
        width: 98%;
        left: 1vw;
      }

      #tickerSearchResults {
        top: 152px;
        width: calc(100vw - 6%);
        left: 2vw;
      }

      #insightHeader {
        margin-bottom: 70px;
      };

      .footerContainer { margin-bottom: 0 }

      .homeContainer {
        margin-bottom: 0;

        input#searchmaster { position: static; }
        #tickerSearchResults { top: 218px; width: 89%; left: 3vw }
      }
      
      .largeImpactScore {
      }

      .instrumentTicker {
        padding-bottom: 40px;
      }
      
      .instrumentMeta {
        .detail {
          font-size: 16px;
          min-width: 100px;
          max-width: 100px;
          line-height: 1em;

          strong { 
            font-size: 2.3em;
            line-height: 1.2em;
          }
        }
  
        .instrumentMeta .detail:nth-child(2) { margin-left: 5px }
      };

      .heroContainer ul.heroClientStats li h4 { 
        font-size: 3em;
      }
    };

    @media only screen and (max-width: 575px) {
      .instrumentMeta {
        display: block;
        
        .detail {
          max-width: 48%;
          min-width: 48%;
        };

        .instrumentMeta .detail:nth-child(2) { margin-left: 10px }
      }
    }
  }

@media print { 
  html, body { height: initial !important; overflow: initial !important; }

  .hideOnMobile, .hideOnTablet, .hideOnPrint {
    display: none;
  }

  .showOnPrint {
    display: block;
  }

  .abbreviateOnPrint {
    display: block !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .inspire-body .insightReportHeader {


    .col-xs-10 {
      max-width: 100%;
      flex-basis: 100%;
    }
    
      h1 {
        font-size: 1.7em;
      }

      h1 span {
        display: block;
      }

      h4 {
        font-size: 1.3em;
      }
    }
  }

